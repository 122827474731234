import React from 'react';
import './App.css';
import Header from './components/Header/Header';
import Home from './components/Home/Home';
import About from './components/About/About';
import Skills from './components/Skills/Skills';
import Qualification from './components/Qualification/Qualification';
import Projects  from './components/Projects/Projects';
import Contact from './components/Contact/Contact';
import Footer  from './components/Footer/Footer';
import Scrollup from './components/ScrollUp/Scrollup';
function App() {
  return (
    <>
        <Header/>
        <main className='main'>
          <Home/>
          <About/>
          <Skills/>
          <Qualification />
          <Projects />
          <Contact />
          <Footer/>
          <Scrollup/>
        </main>
    </>
  );
}

export default App;
